<template>
   <section v-if="dashInfo.length > 0">
     <div class="main-contents-wrapper">
      <h1 style="font-size:30px; padding: 10px 0;">DASHBOARD</h1>
      <p class="dashtext">{{ $t('txt.dashA') }}<br />{{ $t('txt.dashB') }}</p>
       <div class="dashwrap">
          <ul class="dashth">
             <li class="inout">
                <!-- <em class="bgrd"><img src="@/assets/img/icon_cost.svg" /></em> -->
                <ul class="dashlist">
                  <li>{{ $t('table.head.inAmt') }}
                    <span>￦{{dashInfo[0].inCashAmt < 0 ? '-' : ''}}{{ dashInfo[0].inCashAmtStr }}</span>
                  </li>
                  <li>{{ $t('table.head.preDate') }}
                    <span>{{ $t('table.head.preDate') }} {{ $t('table.head.inAmt') }}</span>
                    <span>￦{{dashInfo[1].inCashAmt < 0 ? '-' : ''}}{{ dashInfo[1].inCashAmtStr }}</span>
                  </li>
                  <li>
                    <span>{{ $t('table.body.diff') }}</span>
                    <span>￦{{diffDashInfo.inCashAmt < 0 ? '-' : ''}}{{ diffDashInfo.inCashAmtStr }}</span>
                  </li>
                </ul>
             </li>
             <li class="inout">
                <!-- <em class="bgrd"><img src="@/assets/img/icon_cost.svg" /></em> -->
                <ul class="dashlist">
                  <li>{{ $t('table.head.outAmt') }}
                    <span>￦{{dashInfo[0].outCashAmt < 0 ? '-' : ''}}{{ dashInfo[0].outCashAmtStr }}</span>
                  </li>
                  <li>
                    <span>{{ $t('table.head.preDate') }} {{ $t('table.head.outAmt') }}</span>
                    <span>￦{{dashInfo[1].outCashAmt < 0 ? '-' : ''}}{{ dashInfo[1].outCashAmtStr }}</span>
                  </li>
                  <li>
                    <span>{{ $t('table.body.diff') }}</span>
                    <span>￦{{diffDashInfo.outCashAmt < 0 ? '-' : ''}}{{ diffDashInfo.outCashAmtStr }}</span>
                  </li>
                </ul>
             </li>
             <li class="inout">
                <!-- <em class="bgrd"><img src="@/assets/img/icon_cost.svg" /></em> -->
                <ul class="dashlist">
                  <li>{{ $t('table.head.profit') }}
                    <span>￦{{dashInfo[0].beneAmt < 0 ? '-' : ''}}{{ dashInfo[0].beneAmtStr }}</span>
                  </li>                  
                  <li>
                    <span>{{ $t('table.head.preDate') }} {{ $t('table.head.profit') }}</span>
                    <span>￦{{dashInfo[1].beneAmt < 0 ? '-' : ''}}{{ dashInfo[1].beneAmtStr }}</span>
                  </li>
                  <li>
                    <span>{{ $t('table.body.diff') }}</span>
                    <span>￦{{diffDashInfo.beneAmt < 0 ? '-' : ''}}{{ diffDashInfo.beneAmtStr }}</span>
                  </li>
                </ul>
             </li>
             <li class="new">
                <!-- <em class="bggn"><img src="@/assets/img/icon_nuser.svg" /></em> -->
                <ul class="dashlist">
                  <li>{{ $t('txt.newNumSub') }}
                    <span>{{dashInfo[0].memJoinCntStr}} {{ $t('common.person') }}</span>
                  </li>
                  <li>
                    <span>{{ $t('table.head.preDate') }} {{ $t('txt.numSub') }}</span>
                    <span>{{dashInfo[1].memJoinCntStr}} {{ $t('common.person') }}</span>
                  </li>
                  <li>
                    <span>{{ $t('table.body.difft') }}</span>
                    <span> {{ diffDashInfo.memJoinCntStr }} {{ $t('common.case') }}</span>
                  </li>
                </ul>
             </li>
          </ul>
          <ul class="dashth">
             <li class="bet">
                <!-- <em class="bgyw"><img src="@/assets/img/icon_cost.svg" /></em> -->
                <ul class="dashlist">
                  <li>{{ $t('table.head.betAmt') }}
                    <span>￦{{dashInfo[0].betAmt < 0 ? '-' : ''}}{{ dashInfo[0].betAmtStr }}</span>
                  </li>
                  <li>
                    <span>{{ $t('table.head.preDate') }} {{ $t('table.head.betAmt') }}</span>
                    <span>￦{{dashInfo[1].betAmt < 0 ? '-' : ''}}{{ dashInfo[1].betAmtStr }}</span>
                  </li>
                  <li>
                    <span>{{ $t('table.body.diff') }}</span>
                    <span>￦{{diffDashInfo.betAmt < 0 ? '-' : ''}}{{ diffDashInfo.betAmtStr }}</span>
                  </li>
                </ul>
             </li>
             <li class="bet">
                <!-- <em class="bgyw"><img src="@/assets/img/icon_cost.svg" /></em> -->
                <ul class="dashlist">
                  <li>{{ $t('table.head.winAmt') }}
                    <span>￦{{dashInfo[0].winAmt < 0 ? '-' : ''}}{{ dashInfo[0].winAmtStr }}</span>
                  </li>
                  <li>
                    <span>{{ $t('table.head.preDate') }} {{ $t('table.head.winAmt') }}</span>
                    <span>￦{{dashInfo[1].winAmt < 0 ? '-' : ''}}{{ dashInfo[1].winAmtStr }}</span>
                  </li>
                  <li>
                    <span>{{ $t('table.body.diff') }}</span>
                    <span>￦{{diffDashInfo.winAmt < 0 ? '-' : ''}}{{ diffDashInfo.winAmtStr }}</span>
                  </li>
                </ul>
             </li>
             <li class="bet">
                <!-- <em class="bgyw"><img src="@/assets/img/icon_cost.svg" /></em> -->
                <ul class="dashlist">
                  <li>{{ $t('table.head.wl') }}
                    <span>￦{{dashInfo[0].winLoseAmt < 0 ? '-' : ''}}{{ dashInfo[0].winLoseAmtStr }}</span>
                  </li>
                  <li>
                    <span>{{ $t('table.head.preDate') }} {{ $t('table.head.wl') }}</span>
                    <span>￦{{dashInfo[1].winLoseAmt < 0 ? '-' : ''}}{{ dashInfo[1].winLoseAmtStr }}</span>
                  </li>
                  <li>
                    <span>{{ $t('table.body.diff') }}</span>
                    <span>￦{{diffDashInfo.winLoseAmt < 0 ? '-' : ''}}{{ diffDashInfo.winLoseAmtStr }}</span>
                  </li>
                </ul>
             </li>
             <li class="new">
                <!-- <em class="bggn"><img src="@/assets/img/icon_bet.svg" /></em> -->
                <ul class="dashlist">
                  <li>{{ $t('txt.newNumBet') }}
                    <span>{{dashInfo[0].betCntStr}} {{ $t('common.case') }}</span>
                  </li>
                  <li>
                    <span>{{ $t('table.head.preDate') }} {{ $t('txt.numBet') }}</span>
                    <span>{{dashInfo[1].betCntStr}} {{ $t('common.case') }}</span>
                  </li>
                  <li>
                    <span>{{ $t('table.body.difft') }}</span>
                    <span> {{ diffDashInfo.betCntStr }} {{ $t('common.case') }}</span>
                  </li>
                </ul>
             </li>
          </ul>
          <!-- <ul class="dashth">
             <li>
                <em class="bgbl"><img src="@/assets/img/icon_insure.svg" /></em>
                <a class="open"><img src="@/assets/img/icon_open.svg" /></a>
                <ul class="dashlist">
                   <li>
                      <span>{{ $t("common.credit") }}</span>
                      <span>-</span>
                   </li>
                   <li>
                      <span>POINT</span>
                      <span>-</span>
                   </li>
                   <li class="btnwrap">
                      <a @click="modalOpen('apply')">알 신청</a>
                      <a @click="modalOpen('exchange')">알 환전</a>
                   </li>
                </ul>
             </li>
             <li>
                <em class="bgbl"><img src="@/assets/img/icon_insure.svg" /></em>
                <a class="open"><img src="@/assets/img/icon_open.svg" /></a>
                <ul class="dashlist">
                   <li>
                      <span>보험</span>
                      <span>-</span>
                   </li>
                   <li>
                      <span>POINT</span>
                      <span>-</span>
                   </li>
                   <li class="btnwrap">
                      <a @click="modalOpen2('apply')">보험금 신청</a>
                      <a @click="modalOpen2('exchange')">보험금 환전</a>
                   </li>
                </ul>
             </li>
          </ul> -->
       </div>
      <div class="chartwrap">
        <div class="chart">
          <h3>{{ $t('table.head.deWith') }}</h3>
          <div>
            <vue-apex-charts height="350" type="bar" :options="chartOptions1" :series="series1"></vue-apex-charts>
          </div>
        </div>
        <div class="chart">
          <h3>{{ $t('table.head.betWin') }}</h3>
          <div>
            <vue-apex-charts height="350" type="bar" :options="chartOptions2" :series="series2"></vue-apex-charts>
          </div>
        </div>
        <div class="chart">
          <h3>{{ $t('table.head.uAmt') }}/{{ $t('table.head.uPT') }}</h3>
          <div>
            <vue-apex-charts height="350" type="bar" :options="chartOptions3" :series="series3"></vue-apex-charts>
          </div>
        </div>
      </div>
     </div>
   </section>
</template>

<script>
import VueApexCharts from 'vue3-apexcharts'
import { getDashboard } from '@/api/common'
import { thousand } from '@/libs/utils'

export default {
  name: 'MainDashboard',
  components: {
    VueApexCharts
  },
  data: function () {
    return {
      dashInfo: {},
      diffDashInfo: {},
      series1: [{
        name: '입금',
        data: [670, 430, 350, 112, 187, 670, 430, 350, 112, 187]
      }, {
        name: '출금',
        data: [10, 41, 35, 51, 49, 62, 69, 91, 148, 1111]
      }],
      series2: [{
        name: '베팅금',
        data: [670, 430, 350, 112, 187, 670, 430, 350, 112, 187]
      }, {
        name: '당첨금',
        data: [10, 41, 35, 51, 49, 62, 69, 91, 148, 1111]
      }],
      series3: [{
        name: '유저 보유금',
        data: [670, 430, 350, 112, 187, 670, 430, 350, 112, 187]
      }, {
        name: '유저 보유포인트',
        data: [10, 41, 35, 51, 49, 62, 69, 91, 148, 1111]
      }],
      chartOptions1: {
        labels: ['01-09', '01-10', '01-11', '01-12', '01-13', '01-14', '01-15', '01-16', '01-17', '01-18'],
        chart: {
          height: 350,
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          colors: ['#008FFB', '#00E396'],
          curve: 'straight',
          width: 2
        },
        title: {
          text: '',
          align: 'left'
        },
        tooltip: {
          y: {
            formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
              return thousand(value)
            }
          }
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return thousand(value)
            }
          }
        }
      },
      chartOptions2: {
        labels: ['01-09', '01-10', '01-11', '01-12', '01-13', '01-14', '01-15', '01-16', '01-17', '01-18'],
        colors: ['#DC84F3', '#EF4040'],
        chart: {
          height: 350,
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          colors: ['#DC84F3', '#EF4040'],
          curve: 'straight',
          width: 2
        },
        markers: {
          colors: ['#DC84F3', '#EF4040']
        },
        title: {
          text: '',
          align: 'left'
        },
        tooltip: {
          y: {
            formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
              return thousand(value)
            }
          }
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return thousand(value)
            }
          }
        }
      },
      chartOptions3: {
        labels: ['01-09', '01-10', '01-11', '01-12', '01-13', '01-14', '01-15', '01-16', '01-17', '01-18'],
        colors: ['#FF9843', '#638889'],
        chart: {
          height: 350,
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          colors: ['#FF9843', '#638889'],
          curve: 'straight',
          width: 2
        },
        markers: {
          colors: ['#FF9843', '#638889']
        },
        title: {
          text: '',
          align: 'left'
        },
        tooltip: {
          y: {
            formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
              return thousand(value)
            }
          }
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return thousand(value)
            }
          }
        }
      }
    }
  },
  methods: {
    loadData () {
      getDashboard({}).then(res => {
        const result = res.data

        if (result.resultCode === '0') {
          console.log(result.data)
          const dashInfo = result.data.dashInfo
          const diffDashInfo = {}

          dashInfo.forEach((item, i) => {
            console.log(i)
            for (const key in item) {
              if (key.indexOf('Amt') > 0 || key.indexOf('Cnt') > 0) {
                item[key] = Number(item[key])
                item[key + 'Str'] = thousand(Math.sqrt(Math.pow(item[key], 2)).toString())
              }
            }

            if (i === 1) {
              for (const key in item) {
                if (key.indexOf('Amt') > 0 || key.indexOf('Cnt') > 0) {
                  if (!diffDashInfo[key]) {
                    diffDashInfo[key] = 0
                    diffDashInfo[key + 'Str'] = '0'

                    diffDashInfo[key] = dashInfo[0][key] - dashInfo[1][key]
                    diffDashInfo[key + 'Str'] = thousand(Math.sqrt(Math.pow(diffDashInfo[key], 2)))
                  }
                }
              }
            }
          })
          this.dashInfo = dashInfo
          this.diffDashInfo = diffDashInfo

          this.series1[0].data = []
          this.series1[1].data = []
          this.series2[0].data = []
          this.series2[1].data = []
          this.series3[0].data = []
          this.series3[1].data = []
          this.chartOptions1.labels = []
          this.chartOptions2.labels = []
          this.chartOptions3.labels = []

          const graphInfo = result.data.graphInfo
          graphInfo.forEach(item => {
            for (const key in item) {
              if (key === 'days') {                
                console.log(item[key])
                this.chartOptions1.labels.push(item[key])
                this.chartOptions2.labels.push(item[key])
                this.chartOptions3.labels.push(item[key])                
              }
              if (key === 'inCashAmt') {
                this.series1[0].data.push(item[key])
              }
              if (key === 'outCashAmt') {
                this.series1[1].data.push(item[key])
              }

              if (key === 'betAmt') {
                this.series2[0].data.push(item[key])
              }
              if (key === 'winAmt') {
                this.series2[1].data.push(item[key])
              }

              if (key === 'cashAmt') {
                this.series3[0].data.push(item[key])
              }
              if (key === 'pointAmt') {
                this.series3[1].data.push(item[key])
              }
            }            
          })
        }
      })
    }
  },
  watch: {
  },
  computed: {
  },
  async created () {
    this.loadData()
  }
}
</script>

<style scoped>
.main-contents-wrapper{width: 100%; box-sizing: border-box; max-width: 1920px;}
.dashtext {font-size: 12px;margin-bottom: 29px;line-height: 1.5em;}
.dashwrap {display: flex;gap: 15px;flex-direction: column;width: 100%; min-width: 1100px;}
.dashth {display: flex;gap: 15px;flex-wrap: wrap; width: 100%;}
.dashth em {width: 39px;height: 43px;display: flex;align-items: center;justify-content: center;}
.dashth em img {height: 26px;}
.dashth > li {box-sizing: border-box;width: calc(25% - 12px);height: 140px;border-radius: 5px 5px 0 0;position: relative;background-color: #fff;border: 1px solid #d5d5d5; }
.dashth > li.inout {border-top: 7px solid #eb7a3f; }
.dashth > li.bet {border-top: 7px solid #82a6a4;}
.dashth > li.new {border-top: 7px solid #806fad;}
.dashlist li:first-child {background: #f9f9f9; height: 49px; border-bottom: 1px solid #eee; font-size: 17px; font-weight: 600; display: flex; align-items: center; }
.dashth .open {position: absolute;right: 15px;top: 15px;cursor: pointer;}
/* .dashlist {} */
/* .dashlist li:first-child {margin-top: 0;margin-bottom: 8px;font-size: 16px;color: #000;} */
.dashlist li {padding: 7px 16px;display: flex;justify-content: space-between; font-size: 13px;color: #65666c; box-sizing: border-box;}
.dashlist li:nth-child(2) {font-size: 15px; padding-top: 15px;}
.bgrd {background: url('~@/assets/img/bg_rd.svg');}
.bgyw {background: url('~@/assets/img/bg_yw.svg');}
.bggn {background: url('~@/assets/img/bg_gn.svg');}
.bgbl {background: url('~@/assets/img/bg_bl.svg');}
.dashlist .btnwrap {display: flex;gap: 13px;margin-top: 8px;}
.btnwrap a {width: 100%;height: 25px;display: flex;align-items: center;justify-content: center;background-color: #353535;font-size: 13px;cursor: pointer;color: #fff;}
.chartwrap{
  margin-top:50px;
  display: flex;
  gap: 20px;
  width: 100%;
  flex-wrap: wrap;
}
.chart{
  width: calc(33.33% - 15px);
  display: flex;
  flex-direction: column;
  gap: 20px;
  border: 1px solid #d5d5d5;
  border-top: 7px solid #360137;
  border-radius: 5px 5px 0 0;
  box-sizing: border-box;
}
.chart h3 {
  padding: 7px 16px;
  background: #f9f9f9;
  height: 49px;
  border-bottom: 1px solid #eee;
  font-size: 17px;
  font-weight: 600;
  display: flex;
  align-items: center;
  color: #65666c;
}
.chart > div {
  padding: 10px;
  padding-top: 0;
}
</style>
